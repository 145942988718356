// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-views-doc-tsx": () => import("./../../../src/views/doc.tsx" /* webpackChunkName: "component---src-views-doc-tsx" */),
  "component---src-views-script-tsx": () => import("./../../../src/views/script.tsx" /* webpackChunkName: "component---src-views-script-tsx" */)
}

